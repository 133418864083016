import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/GuardedRoute/LoadingSpinner/LoadingSpinner";
import { useAppDispatch } from "../../redux/hooks";
import { setAccessToken, setUserName } from "../../redux/slices/spotifyAuth.slice";
import { SpotifyService } from "../../services/Spotify.service";

export const Redirect: React.FC = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code') as string;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        SpotifyService.exchangeTokenAndGetUserData(code)
            .then((data) => {
                dispatch(setAccessToken(localStorage.getItem("access_token") || ""));
                dispatch(setUserName(data.display_name));

                navigate("/")
            })
            .catch((err) => {
                console.error(err);
                navigate("/")
            });
    }, []);

    return (
        <div>
            <LoadingSpinner />
        </div>
    );
};

export default Redirect;
