import { FC, useState } from 'react';
import { RoomInterface } from '../../views/Room/Room';
import BottomSheet from '../BottomSheet/BottomSheet';
import CooldownSettings from '../CooldownSettings/CooldownSettings';
import DeleteRoomButton from '../DeleteRoomButton/DeleteRoomButton';
import OutputSelection from '../OutputSelection/OutputSelection';
import "./RoomSettings.scss";

type RoomSettingsProps = {
    room: RoomInterface
}

const RoomSettings: FC<RoomSettingsProps> = ({ room }) => {

    const [showSettings, setShowSettings] = useState(false);

    return (
        <>
            <button type='button' onClick={() => setShowSettings(true)}>Room settings</button>
            {
                showSettings ?
                    <BottomSheet close={() => setShowSettings(false)}>
                        <div className="settings-card">
                            <OutputSelection ownerToken={room.ownerToken} />
                            <DeleteRoomButton roomId={room.roomId} />
                        </div>
                    </BottomSheet>
                    : null
            }
        </>
    )
}

export default RoomSettings;
