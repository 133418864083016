import { Unsubscribe } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/GuardedRoute/LoadingSpinner/LoadingSpinner";
import PlaybackState from "../../components/PlaybackState/PlaybackState";
import SearchTracks from "../../components/SearchTracks/SearchTracks";
import { firestore } from "../../firebase";
import { SpotifyTrackItem } from "../../services/Spotify.service";

import "./Room.scss";

export interface RoomInterface {
    ownerSpotifyUserId: string;
    roomId: string;
    queue: {
        tracks: SpotifyTrackItem[];
    };
    ownerToken: string;
}

const Room = () => {
    const { roomId } = useParams();
    const navigate = useNavigate();

    const [roomData, setRoomData] = useState<RoomInterface | null>(null);

    const [roomsColleciton, loading_, error_] = useCollection(collection(firestore, "rooms"), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    const [roomUnsub, setRoomUnsub] = useState<Unsubscribe | null>(null)

    useEffect(() => {
        return () => {
            if (!roomUnsub) return;

            roomUnsub();
        }
    }, []);

    useEffect(() => {
        if (!roomId || !roomsColleciton) {
            return;
        }

        try {
            const foundRoom = roomsColleciton?.docs.find((doc) => doc.data().roomId === roomId);
            if (!foundRoom || !foundRoom.data()) {
                navigate("/join");
                toastr.warning("Room not found", `No room with id ${roomId} was found`);
                localStorage.setItem("lastRoom", "");
                return;
            }

            localStorage.setItem("lastRoom", foundRoom.id);

            setRoomData(foundRoom.data() as RoomInterface);

            const unsub = onSnapshot(doc(firestore, "rooms", foundRoom.id), (doc) => {
                if (doc.exists()) {
                    /**
                     * TODO
                     * Sync die queue in der PB mit der im Spotify
                     * Vlt auchn timer der jede sekunde die Spotify queue checkt
                     *
                     * currentlyPlaying anzeigen - vlt nen player?
                     *
                     * Refactor:
                     *  - Es macht garnkeinen sinn den AccessToken im Redux zu speichern. Der wird ja eig nur an der höchsten stelle gesetzt und kann dann runtergegeben werden
                     *  - WebDevSimplified hat da nen custom hook für gemacht -> checken
                     */
                    return;
                }

                toastr.warning("Kicked from room", "Room was deleted");
                localStorage.setItem("lastRoom", "");
                navigate("/");
                unsub();
            });

            setRoomUnsub(unsub);
        } catch (err) {
            console.error(err);
        }
    }, [roomId, roomsColleciton]);

    return (
        <div className="Room">
            {
                roomData ? <>
                    <SearchTracks room={roomData} />
                    <PlaybackState  room={roomData} />
                </> : <LoadingSpinner />
            }
        </div>
    );
};

export default Room;
