import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { selectAuthUserName } from "../../redux/slices/spotifyAuth.slice";
import { SpotifyService } from "../../services/Spotify.service";
import "./Home.scss";

const Home = () => {
    const userName = useAppSelector(selectAuthUserName);
    const navigate = useNavigate();

    const toYourRoom = () => {
        navigate("/create-room");
    };

    const login = () => {
        SpotifyService.authUser();
    };

    return (
        <div className="Home">
            <h1>Spotify Song Request</h1>
            <h2>Let your guests decide the music</h2>

            {
                userName ?
                    <>
                        <h3 className="welcome-back">Welcome back {userName}</h3>
                        <button onClick={toYourRoom}>To your room</button>
                    </> :
                    <button onClick={login}>Login with Spotify to get started</button>
            }
        </div >
    )
}

export default Home