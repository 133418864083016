import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectAuthAccessToken } from '../../redux/slices/spotifyAuth.slice';
import { SpotifyCurrentlyPlaying, SpotifyService } from '../../services/Spotify.service';
import { RoomInterface } from '../../views/Room/Room';
import FullpageOverlay from '../FullpageOverlay/FullpageOverlay';
import QueueList from '../QueueList/QueueList';
import "./PlaybackState.scss";

import pause from "../../assets/icons/pause.svg";
import play from "../../assets/icons/play.svg";

type PlaybackStateProps = {
    room: RoomInterface;
}

const PlaybackState: FC<PlaybackStateProps> = ({ room }) => {
    const accessToken = useAppSelector(selectAuthAccessToken);

    const [currentlyPlaying, setCurrentlyPlaying] = useState<SpotifyCurrentlyPlaying | null>(null);

    const [showQueue, setShowQueue] = useState(false);

    useEffect(() => {
        const getCurrentPlaying = () => {
            SpotifyService.getCurrentlyPlaying(room.ownerToken)
                .then(currentlyPlayingResponse => {
                    setCurrentlyPlaying(currentlyPlayingResponse);
                })
                .catch(err => {
                    console.error(err);
                    setCurrentlyPlaying(null)
                })
        }

        getCurrentPlaying();

        const playerInterval = setInterval(getCurrentPlaying, 1000);

        return () => {
            clearInterval(playerInterval);
        }
    }, []);

    const handlePlaybackState = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (!currentlyPlaying) return;

        const newPlayerState = !currentlyPlaying.is_playing;

        try {
            if (newPlayerState) {
                SpotifyService.resumePlayback(room.ownerToken)
            } else {
                SpotifyService.pausePlayback(room.ownerToken)
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <div className={`PlaybackState ${currentlyPlaying?.is_playing ? "playing" : "paused"}`} onClick={() => setShowQueue(!showQueue)}>

                {
                    currentlyPlaying ? <div className='current-track'>
                        <div className="infos">
                            <img src={currentlyPlaying.item.album.images[0].url} />
                            <div className="track">
                                <span><strong>{currentlyPlaying.item.name}</strong> • {currentlyPlaying.item.artists[0].name}</span>
                            </div>
                        </div>
                        {
                            accessToken === room.ownerToken ?
                                <div className="actions">
                                    <button onClick={handlePlaybackState}>{currentlyPlaying.is_playing ?
                                        <img src={pause} /> : <img src={play} />}</button>
                                </div> : null
                        }
                        <div className="progress" style={{ width: `${100 * currentlyPlaying.progress_ms / currentlyPlaying.item.duration_ms}%` }}></div>
                    </div> : <h3>Player not found</h3>
                }
            </div>
            {
                showQueue ?
                    <FullpageOverlay close={() => setShowQueue(false)}>
                        <QueueList room={room} close={() => setShowQueue(false)} />
                    </FullpageOverlay>
                    : null
            }
        </>
    )
}

export default PlaybackState;
