import { FC, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import LoadingSpinner from "./components/GuardedRoute/LoadingSpinner/LoadingSpinner";
import Navigation from "./components/Navigation/Navigation";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
    selectAuthAccessToken, selectAuthUserName,
    setAccessToken,
    setUserName
} from "./redux/slices/spotifyAuth.slice";
import { FirebaseService } from "./services/FirebaseService";
import { SpotifyService } from "./services/Spotify.service";
import CreateRoom from "./views/CreateRoom/CreateRoom";
import Home from "./views/Home/Home";
import JoinRoom from "./views/JoinRoom/JoinRoom";
import Redirect from "./views/Redirect/Redirect";
import Room, { RoomInterface } from "./views/Room/Room";

const App: FC = () => {
    const accessToken = useAppSelector(selectAuthAccessToken);
    const userName = useAppSelector(selectAuthUserName);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const localStorageAccessToken = localStorage.getItem("access_token");
        let localStorageRefreshToken = localStorage.getItem("refresh_token");
        let localStorageExpiresAt = localStorage.getItem("expires_at");
        let expiringTime = new Date(Number(localStorageExpiresAt));

        if (localStorageAccessToken && localStorageRefreshToken && expiringTime > new Date()) {
            SpotifyService.loadUserData(localStorageAccessToken)
                .then(({ display_name }) => {
                    dispatch(setUserName(display_name));
                    dispatch(setAccessToken(localStorageAccessToken));
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        const checkTokenInterval = setInterval(() => {
            localStorageRefreshToken = localStorage.getItem("refresh_token");
            localStorageExpiresAt = localStorage.getItem("expires_at");
            expiringTime = new Date(Number(localStorageExpiresAt));

            if (!localStorageRefreshToken || expiringTime > new Date()) {
                return;
            }

            try {
                SpotifyService.refreshToken(localStorageRefreshToken!).then((updatedUser) => {
                    const newLocalStorageAccessToken = localStorage.getItem("access_token");

                    dispatch(setUserName(updatedUser.display_name));
                    dispatch(setAccessToken(newLocalStorageAccessToken!));
                });
            } catch (err) {
                console.error(err);
            }
        }, 1000);

        return () => {
            clearInterval(checkTokenInterval);
        };
    }, []);

    useEffect(() => {
        if (!accessToken) {
            setLoading(false);
            return;
        }

        try {
            FirebaseService.getOwnerRoom(userName)
                .then(([room]) => {
                    setLoading(false);

                    if (!room) return;

                    FirebaseService.updateRoom(room.id, {
                        ...room.data() as unknown as RoomInterface,
                        ownerToken: accessToken
                    });
                })
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    }, [accessToken, userName]);

    return (
        <div className="App">
            {loading ? <LoadingSpinner /> : null}

            <BrowserRouter>
                <header>
                    <Navigation />
                </header>


                <main>
                    <Routes>
                        <Route path="/home" element={<Home></Home>}></Route>
                        <Route path="/redirect" element={<Redirect />}></Route>
                        <Route path="/create-room" element={<CreateRoom />} />
                        <Route path="/join" element={<JoinRoom />}></Route>
                        <Route path="/room/:roomId" element={<Room />}></Route>
                        <Route path="*" element={<Navigate to="/home"></Navigate>}></Route>
                    </Routes>
                </main>
            </BrowserRouter>
        </div>
    );
};

export default App;
