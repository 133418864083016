import { FC, FormEvent, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useAppSelector } from "../../redux/hooks";
import { selectAuthUserName } from "../../redux/slices/spotifyAuth.slice";
import { FirebaseService } from "../../services/FirebaseService";
import { SpotifyService, SpotifyTrackItem } from "../../services/Spotify.service";
import { RoomInterface } from "../../views/Room/Room";
import Share from "../Share/Share";
import "./SearchTracks.scss";

import plus from "../../assets/icons/plus.svg";
import RoomSettings from "../RoomSettings/RoomSettings";
import TrackDetails from "../TrackDetails/TrackDetails";
import TrackInfoItem from "../TrackInfoItem/TrackInfoItem";

type SearchTracksProps = {
    room: RoomInterface;
}

const SearchTracks: FC<SearchTracksProps> = ({ room }) => {
    const searchTermRef = useRef<HTMLInputElement>(null);
    const loggedInUser = useAppSelector(selectAuthUserName);

    const [searchResults, setSearchResults] = useState<SpotifyTrackItem[]>([]);

    const [trackDetails, setTrackDetails] = useState<SpotifyTrackItem | boolean>(false);

    const searchSpotify = async (e: FormEvent) => {
        e.preventDefault();

        if (!searchTermRef.current || !searchTermRef.current.value) {
            return;
        }

        try {
            const result = await SpotifyService.search(searchTermRef.current.value, room.ownerToken);
            setSearchResults(result.tracks.items);
        } catch (err) {
            console.error(err);
            toastr.error("unable to search", JSON.stringify(err));
        }
    };

    const addToQueue = async (track: SpotifyTrackItem) => {
        try {
            await SpotifyService.addSongToQueue(track.uri, room.ownerToken);

            const updatedTracks: SpotifyTrackItem[] = [
                ...room.queue.tracks,
                {
                    album: {
                        artists: track.album.artists,
                        images: track.album.images,
                        name: track.album.name,
                    },
                    artists: track.artists,
                    duration_ms: track.duration_ms,
                    id: track.id,
                    name: track.name,
                    track_number: track.track_number,
                    uri: track.uri,
                },
            ];

            FirebaseService.updateRoom(room.roomId, { ...room, queue: { tracks: updatedTracks } });

            toastr.success(
                `Successfully added ${track.name} by ${track.artists.map((artist) => artist.name).join(", ")} to queue`,
                ""
            );
        } catch (err) {
            toastr.error("Unable to add track to queue", "Queue not found, make sure that spotify is running");
        }
    };

    return (
        <div id="search-tracks">
            <form onSubmit={searchSpotify}>
                <div className="additional">
                    {
                        room.ownerSpotifyUserId === loggedInUser ?
                            <RoomSettings room={room} />
                            : <div></div>
                    }
                    <Share className="share" roomId={room.roomId} />
                </div>
                <div className="main">
                    <input type="text" placeholder="Searchterm" ref={searchTermRef} />
                    <button type="submit">Search</button>
                </div>
            </form>

            {
                trackDetails ?
                    <TrackDetails
                        close={() => setTrackDetails(false)}
                        track={trackDetails as SpotifyTrackItem}
                        addToQueue={addToQueue}
                    /> : null
            }

            <div className="results">
                {searchResults.map((searchResult, index) => {
                    return (
                        <div className="search-result"
                            key={index}
                            onClick={() => setTrackDetails(searchResult)}>
                            <TrackInfoItem track={searchResult} />
                            <button onClick={(e) => {
                                e.stopPropagation();
                                addToQueue(searchResult);
                            }} className="add-to-queue">
                                <img src={plus} />
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SearchTracks;
