import { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./JoinRoom.scss";

const JoinRoom = () => {
    const navigate = useNavigate();
    const joinRoomRef = useRef<HTMLInputElement>(null);
    const lastRoom = localStorage.getItem("lastRoom") || "";

    const joinRoom = async (e: FormEvent) => {
        e.preventDefault();

        navigate(`/room/${joinRoomRef.current?.value}`)
    };

    return (
        <div className='join-room'>
            <form onSubmit={joinRoom}>
                <input type="text" name="roomId" id="roomId" placeholder="Enter room id" ref={joinRoomRef} required />
                <button type="submit">Join room</button>
            </form>

            {
                lastRoom ? <button onClick={() => navigate(`/room/${lastRoom}`)} className="last-room">Join last room</button> : null
            }
        </div>
    )
}

export default JoinRoom