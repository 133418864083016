import { FC } from 'react';
import { SpotifyTrackItem } from '../../services/Spotify.service';
import "./TrackInfoItem.scss";

interface TrackInfoItemProps extends React.HTMLAttributes<HTMLDivElement> {
    track: SpotifyTrackItem
}

const TrackInfoItem: FC<TrackInfoItemProps> = ({ track, ...props }) => {
    return (
        <div {...props} className={"TrackInfoItem " + props.className} >
            <img src={track.album.images[0].url} />
            <div className="title-artists">
                <div className="title">{track.name}</div>
                <div className="artists">
                    {track.artists.map((artist) => artist.name).join(", ")}
                </div>
            </div>
        </div>
    )
}

export default TrackInfoItem;
