import { FC, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { toastr } from 'react-redux-toastr';
import Dialog from '../Dialog/Dialog';
import "./Share.scss";

interface ShareProps extends React.HTMLAttributes<HTMLButtonElement> {
    roomId: string | undefined;
}

const Share: FC<ShareProps> = ({ roomId, ...rest }) => {

    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeString, setQrCodeString] = useState("");

    useEffect(() => {
        if (!roomId) return;

        setQrCodeString(`${process.env.NODE_ENV === "production" ?
            "https://songrequest.feissn.de/room/" :
            "http://localhost:3000/room/"}${roomId}`);
    }, [roomId])

    const saveRoomLink = () => {
        navigator.clipboard.writeText(qrCodeString);

        toastr.success(`Saved room link to clipboard`, "");
    }

    return (
        <>
            <button type='button' className='Share' onClick={() => setShowQRCode(true)} {...rest}>Share</button>
            {
                showQRCode ? <Dialog close={() => setShowQRCode(false)}>
                    <div className="qrcode-wrapper" onClick={saveRoomLink} >
                        <QRCode value={qrCodeString} />
                    </div>
                </Dialog> : null
            }
        </>
    )
}

export default Share;
