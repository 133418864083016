import { FC, useEffect, useRef } from 'react';
import "./BottomSheet.scss";

type BottomSheetProps = {
    children?: JSX.Element | JSX.Element[] | string;
    close: () => void;
}

const BottomSheet: FC<BottomSheetProps> = ({ children, close }) => {
    const backdropRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = "auto"
        }
    }, [])

    const handleClick = (e: React.MouseEvent) => {
        if (e.target === backdropRef.current) close();
    }

    return (
        <div className='BottomSheet' onClick={handleClick} ref={backdropRef}>
            <div className="content">
                {children}
            </div>
        </div>
    )
}

export default BottomSheet;
