import { FC, useEffect, useState } from 'react';
import { SpotifyService, SpotifyTrackItem } from '../../services/Spotify.service';
import { RoomInterface } from '../../views/Room/Room';
import TrackInfoItem from '../TrackInfoItem/TrackInfoItem';
import "./QueueList.scss";

type QueueListProps = {
    room: RoomInterface;
    close?: () => void;
}

const QueueList: FC<QueueListProps> = ({ room, close }) => {
    const [currentlyPlaying, setCurrentlyPlaying] = useState<SpotifyTrackItem | null>(null);
    const [queueItems, setQueueItems] = useState<SpotifyTrackItem[]>([]);

    useEffect(() => {
        SpotifyService.getQueue(room.ownerToken)
            .then(queue => {
                setQueueItems(queue.queue);
                setCurrentlyPlaying(queue.currently_playing);
            })
            .catch(err => {
                console.error(err);
                setQueueItems([]);
                setCurrentlyPlaying(null);
            });

        return () => {

        }
    }, [])


    return (
        <div className='QueueList'>
            {currentlyPlaying ?
                <div className="current">
                    <h3>Currently playing:</h3>
                    <TrackInfoItem track={currentlyPlaying} />
                </div>
                : null
            }

            <div className="queue">
                <h3>Next in Queue:</h3>
                {queueItems.map(track => <TrackInfoItem className="track" track={track} />)}
            </div>

            <button className='back' onClick={close}>Back</button>
        </div>
    )
}

export default QueueList;
