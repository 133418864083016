import { FC } from 'react';
import { toastr } from 'react-redux-toastr';
import { FirebaseService } from '../../services/FirebaseService';
import "./DeleteRoomButton.scss";

type DeleteRoomButtonProps = {
    roomId: string;
    success?: () => void;
    fail?: (err: any) => void;
}

const DeleteRoomButton: FC<DeleteRoomButtonProps> = ({ roomId, success, fail }) => {
    const deleteRoom = async () => {
        if (!roomId) {
            return;
        }

        try {
            await FirebaseService.deleteRoom(roomId);
            toastr.success("Room deleted", "Your room was successfully removed")

            if (success) {
                success();
            }
        } catch (err) {
            toastr.error("Room was not deleted", "Failed to delete your room")
            if (fail) {
                fail(err)
            }
        }
    };

    return (
        <button onClick={deleteRoom} className="warn" type="button">Delete Room</button>
    )
}

export default DeleteRoomButton;
