import axios from "axios";
import { SpotifyUser } from "../interfaces/spotify.interface";
import { buildQueryParams, generateCodeChallenge, generateRandomString } from "../utils/Spotify.utils";

export interface SpotifyArtist {
    name: string;
}

export interface SpotifyAlbum {
    name: string;
    artists: SpotifyArtist[];
    images: {
        url: string;
        width: number;
        height: number;
    }[];
}

export interface SpotifyTrackItem {
    album: SpotifyAlbum;
    artists: SpotifyArtist[];
    duration_ms: number;
    name: string;
    track_number: string;
    id: string;
    uri: string;
}

export interface SpotifyTracks {
    tracks: {
        href: string;
        items: SpotifyTrackItem[];
        limit: number;
        offset: number;
        total: number;
    };
}

export interface SpotifyDevices {
    devices: {
        id: string;
        is_active: boolean;
        name: string,
        type: string
    }[]
}

export interface SpotifyCurrentlyPlaying {
    is_playing: boolean;
    progress_ms: number;
    item: SpotifyTrackItem;
}

export interface SpotifyQueue {
    currently_playing: SpotifyTrackItem;
    queue: SpotifyTrackItem[]
}

export class SpotifyService {
    private static clientId = "2b2645d6de3b4d31917c0662e7b0bd01";
    private static redirectURI = process.env.NODE_ENV === "production" ?
        "https://songrequest.feissn.de/redirect" :
        "http://localhost:3000/redirect";

    static authUser() {
        const codeVerifier = generateRandomString(64);

        generateCodeChallenge(codeVerifier).then((code_challenge) => {
            window.localStorage.setItem("code_verifier", codeVerifier);

            window.location.href =
                "https://accounts.spotify.com/authorize" +
                buildQueryParams({
                    response_type: "code",
                    client_id: this.clientId,
                    scope: "user-read-private user-read-email user-modify-playback-state app-remote-control user-read-playback-state user-modify-playback-state user-read-currently-playing",
                    code_challenge_method: "S256",
                    code_challenge,
                    redirect_uri: this.redirectURI,
                });
        });
    }

    private static processTokenResponse = (data: any) => {
        const t = new Date();
        const expires_at = t.setSeconds(t.getSeconds() + data.expires_in);

        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("expires_at", `${expires_at}`);

        return this.loadUserData(data.access_token);
    };

    static async exchangeTokenAndGetUserData(code: string) {
        const code_verifier = localStorage.getItem("code_verifier");

        if (!code_verifier) throw new Error("Code verifier not found");

        const body = new URLSearchParams({
            client_id: this.clientId,
            grant_type: "authorization_code",
            code,
            redirect_uri: this.redirectURI,
            code_verifier,
        });

        return fetch("https://accounts.spotify.com/api/token", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body,
        })
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw { response, error: await response.json() };
                }
            })
            .then(this.processTokenResponse)
            .catch((err) => {
                throw err;
            });
    }

    static async loadUserData(accessToken: string): Promise<SpotifyUser> {
        return axios
            .get("https://api.spotify.com/v1/me", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then(({ data }) => {
                return data;
            });
    }

    static async refreshToken(refreshToken: string) {
        return fetch("https://accounts.spotify.com/api/token", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: new URLSearchParams({
                client_id: this.clientId,
                grant_type: "refresh_token",
                refresh_token: refreshToken,
            }),
        })
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw await response.json();
                }
            })
            .then(this.processTokenResponse)
            .catch((err) => {
                throw err;
            });
    }

    static async getCurrentlyPlaying(accessToken: string) {
        return axios
            .get<SpotifyCurrentlyPlaying>("https://api.spotify.com/v1/me/player/currently-playing", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then(({ data }) => {
                return data;
            })
            .catch((err) => {
                throw err;
            });
    }

    static async search(searchString: string, accessToken: string) {
        return axios
            .get<SpotifyTracks>(
                `https://api.spotify.com/v1/search?type=track&include_external=audio&q=${searchString}%20track`,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.response.data);
                throw err.response.data;
            });
    }

    static async addSongToQueue(songUri: string, accessToken: string) {
        return axios
            .post(
                `https://api.spotify.com/v1/me/player/queue?uri=${songUri}`,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.error(err.response.data);
                throw err.response.data;
            });
    }

    static async getQueue(accessToken: string) {
        return axios
            .get<SpotifyQueue>(`https://api.spotify.com/v1/me/player/queue`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.error(err.response.data);
                throw err.response.data;
            });
    }

    static async getAvailableDevices(accessToken: string) {
        return axios.get<SpotifyDevices>(`https://api.spotify.com/v1/me/player/devices`, {
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then(res => res.data)
            .catch(err => {
                console.error(err);
                throw err
            })
    }

    static async setPlaybackDevice(deviceId: string, accessToken: string) {
        return axios.put<SpotifyDevices>(`https://api.spotify.com/v1/me/player`, {
            device_ids: [deviceId]
        }, {
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then(res => res.data)
            .catch(err => {
                console.error(err);
                throw err
            })
    }

    static async resumePlayback(accessToken: string) {
        return axios.put(`https://api.spotify.com/v1/me/player/play`, {}, {
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then(res => res.data)
            .catch(err => {
                console.error(err);
                throw err
            })
    }

    static async pausePlayback(accessToken: string) {
        return axios.put(`https://api.spotify.com/v1/me/player/pause`, {}, {
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then(res => res.data)
            .catch(err => {
                console.error(err);
                throw err
            })
    }
}
