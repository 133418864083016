type QueryParams = {
    [key: string]: any;
};

export const generateRandomString = (length: number) => {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split("");

    if (!length) {
        length = Math.floor(Math.random() * chars.length);
    }

    var str = "";
    for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
};

export async function generateCodeChallenge(codeVerifier: string) {
    // Not working on mobile?
    const digest = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(codeVerifier));

    return window
        .btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/=/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
}

export const buildQueryParams = (params: QueryParams) => {
    let queryParams = "?";
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            queryParams += key + "=" + params[key] + "&";
        }
    }
    return queryParams.slice(0, -1);
};
