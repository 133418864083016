// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAfDWfbA3U93uNKtIMSsYrtyUT5VrJbwBU",
    authDomain: "firerequest-c52ba.firebaseapp.com",
    projectId: "firerequest-c52ba",
    storageBucket: "firerequest-c52ba.appspot.com",
    messagingSenderId: "663306129975",
    appId: "1:663306129975:web:17902ef799be7c8371e5b2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
