import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';

export interface AuthState {
    accessToken: string;
    userName: string;
}

const initialState: AuthState = {
    accessToken: "",
    userName: ""
};

export const spotifyAuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearAuth: (state) => {
            state.accessToken = "";
            state.userName = "";
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        setUserName: (state, action: PayloadAction<string>) => {
            state.userName = action.payload;
        },
    }
});

export const { clearAuth, setAccessToken, setUserName } = spotifyAuthSlice.actions;

export const selectAuthAccessToken = (state: RootState) => state.auth.accessToken;
export const selectAuthUserName = (state: RootState) => state.auth.userName;
export const selectAuthenticated = (state: RootState) => !!state.auth.accessToken;

export default spotifyAuthSlice.reducer;
