import { FC, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useWindowSize } from '../../hooks/resize.hook';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearAuth, selectAuthenticated, selectAuthUserName } from '../../redux/slices/spotifyAuth.slice';
import { SpotifyService } from '../../services/Spotify.service';
import "./Navigation.scss";

type NavigationProps = {

}

const Navigation: FC<NavigationProps> = ({ }) => {
    const authenticated = useAppSelector(selectAuthenticated);
    const userName = useAppSelector(selectAuthUserName);
    const [smallerWidth, setSmallerWidth] = useState(false);
    const [windowWidth, windowHeight] = useWindowSize();
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
    const dispatch = useAppDispatch();

    const logout = async () => {
        localStorage.clear();
        dispatch(clearAuth());
    };

    const login = async () => {
        SpotifyService.authUser();
    };

    useEffect(() => {
        if (!windowWidth || !windowHeight) return;

        if (windowWidth <= 660) setSmallerWidth(true);
        if (windowWidth > 660) setSmallerWidth(false);
    }, [windowWidth, windowHeight]);

    return (
        <div className={`Navigation ${smallerWidth ? "smaller" : ""}`}>
            <Link to="/" className="logo" onClick={() => setIsMenuCollapsed(false)}>
                {smallerWidth ? "SR" : "SongRequest"}
            </Link>

            {smallerWidth && (
                <div
                    className={`burger ${isMenuCollapsed ? "collapsed" : ""}`}
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                >
                    <div className="stripe"></div>
                    <div className="stripe"></div>
                    <div className="stripe"></div>
                </div>
            )}
            <nav className={isMenuCollapsed ? "visible" : "hidden"} onClick={() => setIsMenuCollapsed(false)}>
                <NavLink to="/home" className={({ isActive }) => (isActive ? "active" : undefined)}>
                    Home
                </NavLink>
                <NavLink to="/join" className={({ isActive }) => (isActive ? "active" : undefined)}>
                    Join room
                </NavLink>
                {authenticated ? (
                    <>
                        <NavLink
                            to="/create-room"
                            className={({ isActive }) => (isActive ? "active" : undefined)}
                        >
                            Create room
                        </NavLink>
                        <a onClick={logout}>Logout {userName}</a>
                    </>
                ) : (
                    <>
                        <a onClick={login}>Login</a>
                    </>
                )}
            </nav>
        </div>
    )
}

export default Navigation;
