import { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import DeleteRoomButton from "../../components/DeleteRoomButton/DeleteRoomButton";
import { useAppSelector } from "../../redux/hooks";
import { selectAuthAccessToken, selectAuthUserName } from "../../redux/slices/spotifyAuth.slice";
import { FirebaseService } from "../../services/FirebaseService";
import { generateRandomString } from "../../utils/Spotify.utils";
import { RoomInterface } from "../Room/Room";
import "./CreateRoom.scss"

const CreateRoom = () => {
    const accessToken = useAppSelector(selectAuthAccessToken);
    const loggedInUser = useAppSelector(selectAuthUserName);
    const [myRoom, setMyRoom] = useState<RoomInterface | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (!accessToken || !loggedInUser) return;

        checkMyRoom().then((result) => {
            setMyRoom(result);
        });
    }, [accessToken, loggedInUser]);

    const checkMyRoom = async () => {
        const docs = await FirebaseService.searchForRoom(loggedInUser);
        return Promise.resolve(docs[0]?.data() as RoomInterface);
    };

    const joinRoom = (roomId: string) => {
        navigate(`/room/${roomId}`);
    };

    const createRoom = async () => {
        try {
            const newRoomId = generateRandomString(16);

            await FirebaseService.addRoom({
                ownerSpotifyUserId: loggedInUser,
                ownerToken: accessToken,
                roomId: newRoomId,
                queue: {
                    tracks: [],
                },
            });

            joinRoom(newRoomId);
        } catch (err) {
            console.error(err);
            if (!accessToken) {
                return toastr.error("Error while creating the room", "User not logged in to spotify");
            }
            toastr.error("Error while creating the room", "It was not possible to create a new room");
        }
    };

    const handleRoomDelete = () => {
        setMyRoom(undefined);
    }

    return (
        <div className="CreateRoom">
            {myRoom ? (
                <div className="my-room">
                    <button onClick={() => joinRoom(myRoom.roomId)}>Join your room</button>
                    <DeleteRoomButton roomId={myRoom.roomId} success={handleRoomDelete} />
                </div>
            ) : (
                <button onClick={createRoom}>Create a new Room</button>
            )}
        </div>
    );
};

export default CreateRoom;
