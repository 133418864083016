import { FC, useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { SpotifyDevices, SpotifyService } from '../../services/Spotify.service';
import "./OutputSelection.scss";

type OutputSelectionProps = {
    ownerToken: string;
}

const OutputSelection: FC<OutputSelectionProps> = ({ ownerToken }) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [availableDevices, setAvailableDevices] = useState<SpotifyDevices | null>(null);

    useEffect(() => {
        SpotifyService.getAvailableDevices(ownerToken).then(userDevices => {
            setAvailableDevices(userDevices);
            const activeDevice = userDevices.devices.find(device => device.is_active === true)
            if (activeDevice) {
                setSelectedDeviceId(activeDevice.id);
            }
        });
    }, []);

    useEffect(() => {
        if (!selectedDeviceId) return;

        SpotifyService.setPlaybackDevice(selectedDeviceId, ownerToken)
            .catch(err => {
                toastr.error("Unable to change playback device", "Something went wrong")
            });
    }, [selectedDeviceId]);

    return (
        <select className='OutputSelection' value={selectedDeviceId} onChange={e => setSelectedDeviceId(e.target.value)}>
            {
                availableDevices?.devices.map(device =>
                    <option key={device.id} value={device.id} className={device.is_active ? "active" : ""}>
                        {device.name}
                    </option>
                )
            }
        </select>
    )
}

export default OutputSelection;
