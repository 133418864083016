import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { RoomInterface } from "../views/Room/Room";

export class FirebaseService {
    static async addRoom(roomData: RoomInterface) {
        return setDoc(doc(firestore, "rooms", roomData.roomId), roomData);
    }

    static async updateRoom(id: string, roomData: RoomInterface) {
        return setDoc(doc(firestore, "rooms", id), roomData);
    }

    static async deleteRoom(id: string) {
        return deleteDoc(doc(firestore, "rooms", id));
    }

    static async searchForRoom(searchTerm = "") {
        return getDocs(query(collection(firestore, "rooms"))).then(({ docs }) =>
            docs.filter((doc) => {
                return doc.data().ownerSpotifyUserId.includes(searchTerm) || doc.data().roomId.includes(searchTerm);
            })
        );
    }

    static async getOwnerRoom(ownerId: string) {
        return getDocs(query(collection(firestore, "rooms"))).then(({ docs }) =>
        docs
            // docs.filter((doc) => doc.data().ownerSpotifyUserId === ownerId)
        )
    }
}
