import { FC } from 'react';
import { SpotifyTrackItem } from '../../services/Spotify.service';
import Dialog from '../Dialog/Dialog';
import "./TrackDetails.scss";

type TrackDetailsProps = {
    close: () => void;
    addToQueue: (track: SpotifyTrackItem) => void;
    track: SpotifyTrackItem;
}

const TrackDetails: FC<TrackDetailsProps> = ({ close, track, addToQueue }) => {
    return (
        <div className='TrackDetails'>
            <Dialog close={close}>
                <div className="details-card">
                    <img src={track.album.images[0].url} />
                    <div className="infos">
                        <div className="name">
                            {track.name}
                        </div>
                        <div className="artists">
                            {track.artists.map(artist => artist.name).join(", ")}
                        </div>
                    </div>
                    <button onClick={() => {
                        addToQueue(track);
                        close();
                    }}>Add to queue</button>
                </div>
            </Dialog>
        </div>
    )
}

export default TrackDetails;
